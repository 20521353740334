import { translate } from "./translate";

function renderSmallSpinWheel(isLoggedIn: boolean, eligibleCount?: number) {
  /**
   * Spinwheel DOM element
   */
  const miniWheel = $(`<div class="luckydraw--spin--wheel--mini"><span>${translate("幸运8抽奖")}</span></div>`);

  const smallWheel = $(`<div class="luckydraw--spin--wheel--small ${isLoggedIn ? "logged-in" : ""}">
    <!-- FECASH-4694: Close toggle-small-view as java side did not manage to finish it in FTL -->
    <!--<div class="toggle-small-view" />-->
    <span class="luckydraw-label">${translate("转盘抽奖")}</span>
  </div>`);

  const luckydrawPortal = $(`<div class="luckydraw--spin--portal">
    <iframe src="/util/cash/luckydraw/?transparent&isLoggedIn=${isLoggedIn}" allowTransparency />
  </div>`);

  /**
   * Spinwheel control function
   */
  function openPortal() {
    $(".luckydraw--spin--wheel--small").addClass("scale-in");
    $("body").append(luckydrawPortal);
  }

  function closePortal() {
    $(".luckydraw--spin--wheel--small").removeClass("scale-in");
    $(".luckydraw--spin--portal").remove();
  }

  function toggleView() {
    const body = $("body");
    if (body.find(".luckydraw--spin--wheel--small").length > 0) {
      body.find(".luckydraw--spin--wheel--small").remove();
      body.append(miniWheel);
    } else {
      body.find(".luckydraw--spin--wheel--mini").remove();
      body.append(smallWheel);
    }
  }

  function listenMessage() {
    window.addEventListener("message", function (event) {
      switch (event.data) {
        case "CLOSE":
          closePortal();

          break;
        case "REGISTER":
          closePortal();
          location.href = "register.html";

          break;
        default:
          return;
      }
    });
  }

  /**
   * Spinwheel event binding
   */
  $(document).on("click", ".luckydraw--spin--wheel--small", function (e) {
    e.stopPropagation();
    openPortal();
  });

  $(document).on("click", ".luckydraw--spin--wheel--small .toggle-small-view", function (e) {
    e.stopPropagation();
    toggleView();
  });

  $(document).on("click", ".luckydraw--spin--wheel--mini", function (e) {
    e.stopPropagation();
    toggleView();
  });

  $("body").append(smallWheel);
  listenMessage();
}

interface ISpinWheel {
  iSpinWheelAvailable: boolean;
  isLoggedIn: boolean;
  eligibleCount?: number;
}

function SpinWheel({ iSpinWheelAvailable, isLoggedIn, eligibleCount }: ISpinWheel) {
  if (iSpinWheelAvailable) {
    renderSmallSpinWheel(isLoggedIn, eligibleCount);
  }
}

export default SpinWheel;
