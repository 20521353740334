import { getApUid } from "src/api/categories/config";

class ApiNotice {
  ajax: any;
  constructor(ajax) {
    this.ajax = ajax;
  }
  getNotice = callback => {
    const { ajax } = this;
    ajax.get("/web/rest/publicNotice", { headers: { apuid: getApUid() } }).then(function(response) {
      if (response) {
        if (callback) {
          callback(response);
        }
      }
    });
  };

  getTrans = callback => {
    const { ajax } = this;
    ajax.get("/web/rest/trans", { headers: { apuid: getApUid() } }).then(function(response) {
      if (response) {
        if (callback) {
          callback(response);
        }
      }
    });
  };
}

export default ApiNotice;
