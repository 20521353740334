// depends on jQuery
//  pass an obj
//  alertModal({
//    message: string,
//    title: string,
//    confirmFunc: function,
//    cancelFunc: function
//  })
//

import { translate } from "./translate";

// import { $ } from "vendors/index";
class AlertBox {
  $: any;
  constructor($) {
    this.$ = $;
    this.init();
  }

  init = () => {
    const { $ } = this;
    $.fn.modalDraggable = function () {
      const $document = $(document);
      interface IMouse {
        x: number;
        y: number;
        update: (e) => void;
      }
      const mouse: IMouse = {
        x: 0,
        y: 0,
        update: function (e) {
          this.x = e.pageX;
          this.y = e.pageY;
        },
      };

      return this.each(function () {
        const $elem = $(this);
        $elem.bind("mousedown.drag", function (e) {
          mouse.update(e);
          if (!/^(relative|absolute)$/.test($elem.css("position"))) {
            $elem.css("position", "relative");
          }
          $document.bind("mousemove.drag", function (e) {
            $elem.css({
              left: (parseInt($elem.css("left")) || 0) + (e.pageX - mouse.x) + "px",
              top: (parseInt($elem.css("top")) || 0) + (e.pageY - mouse.y) + "px",
            });
            mouse.update(e);
            e.preventDefault();
          });
          $document.one("mouseup.drag", function (e) {
            $document.unbind("mousemove.drag");
          });
          e.preventDefault();
        });
      });
    };

    window.alertBox = (obj) => {
      const { title, message, cancelFunc, confirmFunc, buttonText, messageList } = obj;
      const confirmButton = $(
        `<button class="btn-confirm">${
          buttonText && buttonText.confirmText ? buttonText.confirmText : translate("确定")
        }</button>`
      );
      const cancelButton = $(
        `<button class="btn-cancel">${
          buttonText && buttonText.cancelText ? buttonText.cancelText : translate("取消")
        }</button>`
      );
      const actions = $(`<div class="action"></div>`);
      const type = obj.type ? obj.type : "info";
      const iconSet = {
        success: $(
          `<div class="alert-box-icon alert-box-success alert-box-animate-success-icon" style="display: flex;">
              <div class="alert-box-success-circular-line-left" style="background-color: #a5dc86;"></div>
              <span class="alert-box-success-line-tip"></span>
              <span class="alert-box-success-line-long"></span>
              <div class="alert-box-success-ring"></div>
              <div class="alert-box-success-fix" style="background-color: #a5dc86;"></div>
              <div class="alert-box-success-circular-line-right" style="background-color: #a5dc86;"></div>
            </div>`
        ),
        error: $(
          `<div class="alert-box-icon alert-box-error alert-box-animate-error-icon" style="display: flex;">
              <span class="alert-box-x-mark">
              <span class="alert-box-x-mark-line-left"></span>
              <span class="alert-box-x-mark-line-right"></span></span>
            </div>`
        ),
        info: $(
          `<div class="alert-box-icon alert-box-info alert-box-animate-info-icon" style="display: flex;">
              <span class="alert-box-icon-text">i</span>
            </div>`
        ),
        warning: $(
          `<div class="alert-box-icon alert-box-warning alert-box-animate-warning-icon" style="display: flex;">
              <span class="alert-box-icon-text">!</span>
            </div>`
        ),
        question: $(
          `<div class="alert-box-icon alert-box-question alert-box-animate-question-icon" style="display: flex;">
              <span class="alert-box-icon-text">?</span>
            </div>`
        ),
      };

      if (confirmFunc) {
        confirmButton.click(confirmFunc);
      }

      if (cancelFunc) {
        cancelButton.click(cancelFunc);
        actions.append(cancelButton);
      }

      cancelButton.click(destroy);
      confirmButton.click(destroy);

      actions.append(confirmButton);
      let errorList = [];

      if (messageList && messageList.length > 0) {
        messageList.forEach((element) => {
          errorList.push(`<p>${element}</p>`);
        });
      }

      const modal = $(`
          <div class="alert-box ${type ? type : ""}" aria-hidden="false">
            ${title ? `<h1>${title}</h1>` : ``}
            ${message ? `<p>${message}</p>` : ``}
            ${messageList ? errorList.join("") : ""}
          </div>
        `);

      modal.prepend(iconSet[type]);

      modal.modalDraggable();

      const alertBoxWrapper = $(`<div class="alert-box-wrapper" aria-hidden="false"></div>`);
      alertBoxWrapper.append(modal);
      modal.append(actions);
      bindKeyboardEvent();

      function modalEvent(e) {
        const keycode = e.keyCode;
        // Keycode 27 = Escape key
        // Keycode 13 = Enter key
        if (keycode === 27) {
          if (cancelFunc) {
            cancelButton.trigger("click");
          } else {
            confirmButton.trigger("click");
          }
        } else if (keycode === 13) {
          confirmButton.trigger("click");
        } else {
          return false;
        }
      }

      function destroy() {
        cancelButton.unbind();
        confirmButton.unbind();
        $(document).unbind("keydown", modalEvent);
        alertBoxWrapper.on("animationend", function () {
          alertBoxWrapper.remove();
        });
        alertBoxWrapper.attr("aria-hidden", "true", function () {
          alertBoxWrapper.remove();
        });
      }

      function bindKeyboardEvent() {
        $(document).keydown(modalEvent);
      }

      $("body").append(alertBoxWrapper);
      confirmButton.focus();
    };
  };
}

export default AlertBox;
