import { getApUid } from "src/api/categories/config";
import { translate } from "src/lib/translate";

const { alertBox } = window;

class ApiPromo {
  ajax: any;
  constructor(ajax) {
    this.ajax = ajax;
  }
  getPromo = (callback) => {
    const { ajax } = this;
    ajax.get("/web/rest/promo", { headers: { apuid: getApUid() } }).then(function (response) {
      if (response) {
        if (callback) {
          callback(response);
        }
      }
    });
  };
  getLoggedInPromo = (callback) => {
    const { ajax } = this;
    ajax.get("/web/rest/member/promo", { headers: { apuid: getApUid() } }).then(function (response) {
      if (response) {
        if (callback) {
          callback(response);
        }
      }
    });
  };
  registerPromo = async (promoId, callback) => {
    const { ajax } = this;
    try {
      const result = await ajax.get("/web/rest/member/checkPromoRegistration?promoId=" + promoId, {
        headers: { apuid: getApUid() },
      });
      const response = result.data;
      const isEligible = !response.result;

      if (isEligible) {
        window.alertBox({
          message: translate("是否确定申请此活动，活动一经参加无法取消"),
          type: "info",
          confirmFunc: () => this.registerPromoConfirm(promoId, callback),
          cancelFunc: () => {},
          buttonText: {
            confirmText: translate("马上加入"),
          },
        });
      } else {
        window.alertBox({
          message: response.result, // Error message return as result
          type: "info",
        });
      }
    } catch (err) {
      const { response } = err;
      if (response) {
        const {
          status,
          data: { message },
        } = response;
        window.alertBox({
          message: message ? message : status.toString(),
          type: "error",
        });
      }
    }
  };
  registerPromoConfirm = async (promoId, callback) => {
    const { ajax } = this;
    try {
      const result = await ajax.get("/web/rest/member/registerPromo?promoId=" + promoId, {
        headers: { apuid: getApUid() },
      });
      const response = result.data;
      if (response && response.status === "success") {
        const { firstDepositPromoJoinMsg, registrationStatus } = response.result;
        const isSuccess = firstDepositPromoJoinMsg && firstDepositPromoJoinMsg.includes(translate("成功"));
        window.alertBox({
          message: firstDepositPromoJoinMsg,
          type: isSuccess ? "success" : "info",
          confirmFunc: function () {
            if (callback) {
              callback();
            } else {
              window.location.reload();
            }
          },
        });
      } else {
        window.alertBox({
          message: translate("您还没有登录"),
          type: "error",
        });
      }
    } catch (err) {
      console.log(err);
      const { response } = err;
      if (response) {
        const {
          status,
          data: { message, statusCode },
        } = response;
        window.alertBox({
          message: message ? message : status.toString(),
          type: "error",
        });
      }
    }
  };
}

export default ApiPromo;
