import { translate } from "./translate";

const BASE_URL = /localhost/.test(location.host) ? "/api" : "";

const initialState = {
  uuid: "",
  positionX: 0,
  positionY: 0,
  puzzleImage: "",
  backgroundImage: "",
  code: "",
  cryptograph: "",
};

const HINTS_COLOR = {
  OK: "#14A371",
  INVALID: "#F16635",
  REFRESH: "#F29E43",
  ERROR: "#E50000",
};

interface ISlideData {
  uuid: string;
  positionX?: number;
  positionY: number;
  puzzleImage: string;
  backgroundImage: string;
  code?: string;
  cryptograph?: string;
}

let slideData: ISlideData = initialState;

let _status;

function renderCaptchaModal(selector, callback) {
  const captchaModalStyle = `
      @keyframes SwipeCaptchaPlaceholderShimmer {
        0% {
          background-position: -468px 0;
        }

        100% {
          background-position: 468px 0;
        }
      }

      @keyframes SwipeCaptchaFadeIn {
        from {
          background-color: rgba(0, 0, 0, 0);
        }
        to {
          background-color: rgba(0, 0, 0, 0.7);
        }
      }

      @keyframes SwipeCaptchaFadeOut {
        0% {
          opacity: 1;
          transform: scale(0.95);
        }
        50% {
          transform: scale(1.05);
        }
        100% {
          opacity: 0;
          transform: scale(0);
        }
      }

      @keyframes SwipeCaptchaZoomIn {
        0% {
          transform: scale(0.95);
        }
        50% {
          transform: scale(1.05);
        }
        100% {
          transform: scale(1);
        }
      }

      .swipe-captcha__overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 20;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        animation-name: SwipeCaptchaFadeIn;
        animation-duration: 0.2s;
        animation-timing-function: ease;
        animation-fill-mode: both;
      }

      .swipe-captcha__overlay.hide {
        animation-name: SwipeCaptchaFadeOut;
        animation-duration: 0.3s;
        animation-timing-function: ease;
        animation-fill-mode: both;
      }

      .swipe-captcha__modal-container {
        background-color: #fff;
        max-height: 80vh;
        border-radius: 8px;
        box-sizing: border-box;
        padding: 16px 12px;
        animation-name: SwipeCaptchaZoomIn;
        animation-duration: 0.2s;
        animation-timing-function: ease-out;
        animation-fill-mode: both;
      }

      .swipe-captcha__captcha-puzzle {
        width: 65px;
        height: 65px;
        top: 0;
        left: 0;
        position: absolute;
        background-repeat: no-repeat;
      }

      .swipe-captcha__background-container {
        width: 250px;
        height: 160px;
        position: relative;
        background-size: 100%;
        background-repeat: no-repeat;
        overflow: hidden;
        background-color: #e0e0e0;
      }

      .swipe-captcha__background-container.loading:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-repeat: no-repeat;
        background-size: 800px 104px;
        background: #f6f7f8;
        background-image: linear-gradient(
          to right,
          #f6f7f8 0%,
          #edeef1 20%,
          #f6f7f8 40%,
          #f6f7f8 100%
        );
        z-index: 10;
        animation: SwipeCaptchaPlaceholderShimmer 2s linear infinite forwards;
      }

      .swipe-captcha__status-hint {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        color: #fff;
        font-size: 12px;
        padding: 4px 6px;
        box-sizing: border-box;
        transition: all 0.2s ease-in-out;
        transform: translateY(100%);
      }
      .swipe-captcha__status-hint.show {
        transform: translateY(0);
      }

      .swipe-captcha__draggable-container {
        width: 100%;
        position: relative;
      }

      .swipe-captcha__captcha-indicator {
        position: absolute;
        left: 0;
        top: 0;
        margin-top: -14px;
        margin-left: -9px;
        width: 65px;
        height: 65px;
      }

      .swipe-captcha__slide-path {
        margin: 12px 0;
        box-sizing: border-box;
        padding: 0 12px;
        font-size: 12px;
        height: 32px;
        border-radius: 24px;
        line-height: 32px;
        text-align: center;
        vertical-align: middle;
        border: 1px solid #ccc;
        background-image: linear-gradient(
          to bottom,
          #d6d6d6,
          #e0e0e0 15%,
          #e0e0e0 85%,
          #d6d6d6
        );
      }

      .swipe-captcha__slide-pad {
        position: relative;
        cursor: grab;
      }

      .swipe-captcha__slide-pad.grab {
        cursor: grabbing;
      }

      .swipe-captcha__slide-pad > svg {
        width: 65px;
        height: 65px;
        left: 0;
        position: absolute;
        background-size: contain;
      }

      .swipe-captcha__slide-pad > svg.hide {
        opacity: 0;
      }

      .swipe-captcha__footer-container {
        padding-top: 8px;
        border-top: 1px solid #eee;
        display: flex;
        align-items: center;
      }

      .swipe-captcha__footer-container > div {
        width: 24px;
        height: 24px;
      }`;

  const captchaModalDOM = `<div class="swipe-captcha__overlay">
    <div class="swipe-captcha__modal-container">
      <div class="swipe-captcha__background-container">
        <div
          class="swipe-captcha__captcha-puzzle"
          style="top: 40px; background-image: url('#1'); left: 0px"
        ></div>
        <div class="swipe-captcha__status-hint"></div>
      </div>
      <div class="swipe-captcha__draggable-container">
        <div class="swipe-captcha__slide-path">${translate("拖动左边滑块完成上方拼图")}</div>
        <div class="swipe-captcha__captcha-indicator" style="transform: none">
          <div class="swipe-captcha__slide-pad">
            <svg
              class="swipe-captcha__slide-pad-grabbing hide"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="66"
              height="66"
              viewBox="0 0 66 66"
            >
              <defs>
                <style>
                  .swipe-dot-a {
                    stroke: #ccc;
                    fill: url(#swipe-dot-a);
                  }
                  .swipe-dot-b {
                    fill: #27c989;
                  }
                  .swipe-dot-c {
                    fill: none;
                  }
                  .swipe-dot-d {
                    stroke: none;
                  }
                  .swipe-dot-e {
                    filter: url(#swipe-dot-b);
                  }
                </style>
                <linearGradient
                  id="swipe-dot-a"
                  x1="0.5"
                  x2="0.5"
                  y2="1"
                  gradientUnits="objectBoundingBox"
                >
                  <stop offset="0" stop-color="#fff" />
                  <stop offset="1" stop-color="#e8e8e8" />
                </linearGradient>
                <filter
                  id="swipe-dot-b"
                  x="0"
                  y="0"
                  width="66"
                  height="66"
                  filterUnits="userSpaceOnUse"
                >
                  <feOffset dy="3" input="SourceAlpha" />
                  <feGaussianBlur stdDeviation="3" result="c" />
                  <feFlood flood-opacity="0.161" />
                  <feComposite operator="in" in2="c" />
                  <feComposite in="SourceGraphic" />
                </filter>
              </defs>
              <g transform="translate(-39 -290)">
                <g class="swipe-dot-e" transform="matrix(1, 0, 0, 1, 39, 290)">
                  <g class="swipe-dot-a" transform="translate(9 6)">
                    <circle class="swipe-dot-d" cx="24" cy="24" r="24" />
                    <circle class="swipe-dot-c" cx="24" cy="24" r="23.5" />
                  </g>
                </g>
                <g transform="translate(0 -111)">
                  <path
                    class="swipe-dot-b"
                    d="M14,7,9,12l5,5Z"
                    transform="translate(51 419)"
                  />
                  <path
                    class="swipe-dot-b"
                    d="M10,17l5-5L10,7Z"
                    transform="translate(69 419)"
                  />
                  <circle
                    class="swipe-dot-b"
                    cx="3"
                    cy="3"
                    r="3"
                    transform="translate(69 428)"
                  />
                  <path
                    class="swipe-dot-c"
                    d="M0,0H24V24H0Z"
                    transform="translate(60 419)"
                  />
                </g>
              </g>
            </svg>
            <svg
              class="swipe-captcha__slide-pad-grab"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="66"
              height="66"
              viewBox="0 0 66 66"
            >
              <defs>
                <style>
                  .swipe-dot-2-a {
                    stroke: #ccc;
                    fill: url(#swipe-dot-2-a);
                  }
                  .swipe-dot-2-b {
                    fill: #27c989;
                  }
                  .swipe-dot-2-c {
                    fill: none;
                  }
                  .swipe-dot-2-d {
                    stroke: none;
                  }
                  .swipe-dot-2-e {
                    filter: url(#swipe-dot-2-b);
                  }
                </style>
                <linearGradient
                  id="swipe-dot-2-a"
                  x1="0.5"
                  x2="0.5"
                  y2="1"
                  gradientUnits="objectBoundingBox"
                >
                  <stop offset="0" stop-color="#fff" />
                  <stop offset="1" stop-color="#e8e8e8" />
                </linearGradient>
                <filter
                  id="swipe-dot-2-b"
                  x="0"
                  y="0"
                  width="66"
                  height="66"
                  filterUnits="userSpaceOnUse"
                >
                  <feOffset dy="3" input="SourceAlpha" />
                  <feGaussianBlur stdDeviation="3" result="c" />
                  <feFlood flood-opacity="0.161" />
                  <feComposite operator="in" in2="c" />
                  <feComposite in="SourceGraphic" />
                </filter>
              </defs>
              <g transform="translate(-39 -290)">
                <g class="swipe-dot-2-e" transform="matrix(1, 0, 0, 1, 39, 290)">
                  <g class="swipe-dot-2-a" transform="translate(9 6)">
                    <circle class="swipe-dot-2-d" cx="24" cy="24" r="24" />
                    <circle class="swipe-dot-2-c" cx="24" cy="24" r="23.5" />
                  </g>
                </g>
                <g transform="translate(4 -3)">
                  <path
                    class="swipe-dot-2-b"
                    d="M19819.16,3811.968a1.561,1.561,0,1,1,0-3.121h15.617a1.561,1.561,0,1,1,0,3.121Zm0-6.636a1.563,1.563,0,0,1,0-3.126h15.617a1.563,1.563,0,0,1,0,3.126Zm0-6.636a1.563,1.563,0,0,1,0-3.126h15.617a1.563,1.563,0,0,1,0,3.126Z"
                    transform="translate(-3735.57 20150.34) rotate(-90)"
                  />
                  <path
                    class="swipe-dot-2-c"
                    d="M0,0H24V24H0Z"
                    transform="translate(56 311)"
                  />
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
      <div class="swipe-captcha__footer-container">
        <div class="swipe-captcha__close">
          <svg width="100%" height="100%" viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path
              d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41zM12 2a10 10 0 1 0 10 10A9.991 9.991 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8.011 8.011 0 0 1-8 8z"
              fill="#767676"
            ></path>
          </svg>
        </div>
        <div class="swipe-captcha__refresh">
          <svg width="100%" height="100%" viewBox="0 0 24 24">
            <rect
              x="0"
              y="0"
              width="24"
              height="24"
              rx="15"
              ry="15"
              fill="transparent"
            ></rect>
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path
              d="M17.65 6.35A8 8 0 1 0 19.73 14h-2.08A6 6 0 1 1 12 6a5.915 5.915 0 0 1 4.22 1.78L13 11h7V4z"
              fill="#767676"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  </div>`;

  document.head.insertAdjacentHTML("beforeend", `<style>${captchaModalStyle}</style>`);

  $(selector).append($(captchaModalDOM));
  if (callback) {
    callback();
  }
}

function generateCaptcha() {
  $(".swipe-captcha__background-container").addClass("loading");
  $.ajax({
    url: BASE_URL + "/web/rest/captcha/generate",
    method: "get",
    success: function (data) {
      const result = data.result;
      const { backgroundImage, positionY, puzzleImage, uuid } = result;
      slideData = {
        backgroundImage,
        positionY,
        puzzleImage,
        uuid,
      };

      $(".swipe-captcha__background-container").css("background-image", `url(${backgroundImage})`);
      $(".swipe-captcha__captcha-puzzle")
        .css("background-image", `url(${puzzleImage})`)
        .css("top", positionY + "px");
    },
    error: function (error) {
      alert(error);
      _status = "ERROR";
      setIndicator("ERROR", "", () => {
        console.log("error");
      });
    },
  }).done(function () {
    $(".swipe-captcha__background-container").removeClass("loading");
  });
}

function validate(positionX, successCallback, closeCallback) {
  if (!slideData.uuid) {
    setIndicator("ERROR", "", () => {
      console.log("error");
    });
    return;
  }

  $.ajax({
    url: BASE_URL + "/web/rest/captcha/validate",
    method: "post",
    dataType: "json",
    contentType: "application/json;charset=utf-8",
    data: JSON.stringify({
      uuid: slideData.uuid,
      positionX: positionX,
      positionY: slideData.positionY,
    }),
    success: function (data) {
      const { result, message } = data;
      const { status, code, cryptograph } = result;
      if (status === "OK" && code && cryptograph) {
        _status = status;
        setIndicator(status, message, closeCallback);

        successCallback({
          cryptograph,
          code,
        });
      }

      if (status === "INVALID") {
        _status = status;
        setIndicator(status, message, closeCallback);
        resetPosition();
      }

      if (status === "REFRESH") {
        _status = status;
        setIndicator(status, message, closeCallback);
        cleanCaptchaValue();
      }
    },
  });
}

function cleanCaptchaValue() {
  slideData = initialState;
  resetPosition();
}

function resetPosition() {
  $(".swipe-captcha__captcha-indicator").animate({
    left: "0px",
  });
  $(".swipe-captcha__captcha-puzzle").animate({
    left: "0px",
  });
}

function setIndicator(status, message, closeCallback) {
  if (status === "OK" || status === "ERROR") {
    closeCaptcha({
      closeCallback,
    });
  }

  if (status === "REFRESH") {
    // close modal immediately
    refresh();
  }

  if (status === "OK" || status === "INVALID" || status === "REFRESH") {
    const hintColor = HINTS_COLOR[status];
    $(".swipe-captcha__status-hint").addClass("show").text(message).css("background-color", hintColor);

    setTimeout(() => {
      $(".swipe-captcha__status-hint").removeClass("show").text("").css("background-color", "transparent");
    }, 1200);
  }
}

function closeCaptcha({ closeCallback }) {
  $(".swipe-captcha__overlay").remove();
  if (closeCallback) {
    closeCallback();
  }
}

function refresh() {
  generateCaptcha();
  cleanCaptchaValue();
}

function init({ successCallback, closeCallback }) {
  $(".swipe-captcha__captcha-indicator").draggable({
    axis: "x",
    containment: "parent",
    drag: function (event, ui) {
      $(".swipe-captcha__slide-pad-grab").addClass("hide");
      $(".swipe-captcha__slide-pad-grabbing").removeClass("hide");
      $(".swipe-captcha__slide-pad").addClass("grab");

      const positionLeft = ui.position.left;
      $(".swipe-captcha__captcha-puzzle").css("left", positionLeft + "px");
    },
    stop: function (event, ui) {
      $(".swipe-captcha__slide-pad-grab").removeClass("hide");
      $(".swipe-captcha__slide-pad-grabbing").addClass("hide");
      $(".swipe-captcha__slide-pad").removeClass("grab");

      const endPositionLeft = ui.position.left;
      validate(endPositionLeft, successCallback, closeCallback);
    },
  });

  $(".swipe-captcha__refresh").click(function () {
    refresh();
  });

  $(".swipe-captcha__close").click(function () {
    closeCaptcha({
      closeCallback,
    });
  });
}

function SwipeCaptcha({ selector, successCallback, closeCallback }) {
  renderCaptchaModal(selector, function () {
    init({
      successCallback,
      closeCallback,
    });
    generateCaptcha();
  });
}

export default SwipeCaptcha;
