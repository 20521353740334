// import { $ } from "vendors/index";
// depends on jQuery


const initTweenValue = function($) {
  if ($(".section-services").length) {
    $(window).on("scroll", sectionServicesAnim);

    if (window.Pace) {
      window.Pace.on("done", sectionServicesAnim);
    } else {
      $(window).load(sectionServicesAnim);
    }
  }

  // execute once when scroll passing
  function sectionServicesAnim() {
    if ($(".section-services").offset().top < $(window).scrollTop() + window.innerHeight - 200) {
      $("[data-start]").each(function(i, elem) {
        var startValue = $(this).attr("data-start");
        var endValue = $(this).attr("data-end");
        var type = $(this).attr("data-type");
        var duration = $(this).attr("data-duration");
        tweenValue({ start: startValue, end: endValue, elem: elem, type: type, duration: duration });
      });
      $(window).unbind("scroll", sectionServicesAnim);
    }
  }
};

function tweenValue(obj) {
  var current = Number(obj.start);
  var start = Number(obj.start);
  var end = Number(obj.end);
  var elem = obj.elem;
  var frames = ((Number(obj.duration) || 1000) / 1000) * 60;
  var step = 0;
  function doNext() {
    if (step < frames) {
      step++;
      var easing = EasingFunctions.easeOutQuint(step / frames);
      current = start + ((end - start) / frames) * step * easing;
      var display: number | string = Math.round(current);
      requestAnimationFrame(doNext);
    } else {
      display = end;
    }
    switch (obj.type) {
      case "time":
        display = toTime(current);
        break;
    }
    elem.innerText = display;
  }
  requestAnimationFrame(doNext);
}

function toTime(number) {
  var min = Math.floor(number / 60);
  var sec = Math.floor(number - min * 60);
  return min + "'" + (sec < 10 ? "0" : "") + sec;
}
/*
 * Easing Functions - inspired from http://gizma.com/easing/
 * only considering the t value for the range [0, 1] => [0, 1]
 */
var EasingFunctions = {
  // no easing, no acceleration
  linear: function(t) {
    return t;
  },
  // accelerating from zero velocity
  easeInQuad: function(t) {
    return t * t;
  },
  // decelerating to zero velocity
  easeOutQuad: function(t) {
    return t * (2 - t);
  },
  // acceleration until halfway, then deceleration
  easeInOutQuad: function(t) {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  },
  // accelerating from zero velocity
  easeInCubic: function(t) {
    return t * t * t;
  },
  // decelerating to zero velocity
  easeOutCubic: function(t) {
    return --t * t * t + 1;
  },
  // acceleration until halfway, then deceleration
  easeInOutCubic: function(t) {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
  },
  // accelerating from zero velocity
  easeInQuart: function(t) {
    return t * t * t * t;
  },
  // decelerating to zero velocity
  easeOutQuart: function(t) {
    return 1 - --t * t * t * t;
  },
  // acceleration until halfway, then deceleration
  easeInOutQuart: function(t) {
    return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
  },
  // accelerating from zero velocity
  easeInQuint: function(t) {
    return t * t * t * t * t;
  },
  // decelerating to zero velocity
  easeOutQuint: function(t) {
    return 1 + --t * t * t * t * t;
  },
  // acceleration until halfway, then deceleration
  easeInOutQuint: function(t) {
    return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
  }
};

export default initTweenValue;