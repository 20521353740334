// import { $ } from "vendors/index";
// depends on jQuery
// marquee

const initMarquee = function($) {
  const marquees = $(".marquee");
  marquees.each(function(i, elem) {
    const speed = $(elem).attr("speed") || 1;
    const text = elem.innerText;
    const textElem = $(`<div>${text}</div>`);
    textElem
      .css("min-width", "100%")
      .css("display", "inline-block")
      .css("margin-right", "40px")
      .css("box-sizing", "border-box");
    $(elem)
      .text("")
      .css("overflow", "hidden");
    const textWrapper = $("<div></div>")
      .css("white-space", "nowrap")
      .css("will-change", "transform");
    textWrapper.append(textElem);
    textWrapper.append(textElem.clone());
    $(elem).append(textWrapper);

    const dynamicStyle = document.createElement("style");
    dynamicStyle.type = "text/css";
    let keyFrames = `
      @-webkit-keyframes dynamicMarqueeAnimation {
        100% {
          -webkit-transform: translateX(DYNAMIC_VALUE);
          transform: translateX(DYNAMIC_VALUE);
      }
      }
      @-moz-keyframes dynamicMarqueeAnimation {
        100% {
          -webkit-transform: translateX(DYNAMIC_VALUE);
          transform: translateX(DYNAMIC_VALUE);
      }
      }
      @keyframes dynamicMarqueeAnimation {
        100%{
          -webkit-transform: translateX(DYNAMIC_VALUE);
          transform: translateX(DYNAMIC_VALUE);
        }
      }
      `;
    document.querySelector("head").append(dynamicStyle);
    function marqueeRun() {
      const width = textElem.width() + 40;
      dynamicStyle.innerHTML = keyFrames.replace(/DYNAMIC_VALUE/g, `-${width}px`);
      textWrapper.css("animation", `dynamicMarqueeAnimation ${(width * 20) / speed}ms linear infinite`);
    }

    textWrapper.hover(
      function() {
        $(this).css("animation-play-state", "paused");
      },
      function() {
        $(this).css("animation-play-state", "running");
      }
    );

    marqueeRun();

    // pause looping when window resizing
    $(window).on("resize", marqueeRun);

    // stop looping text when marquee is out of viewport
    $(window).on("scroll", function() {
      const boundaries = elem.getBoundingClientRect();
      if (boundaries.top < 0 || boundaries.bottom > window.innerHeight || boundaries.height <= 1) {
        textWrapper.css("animation-play-state", "paused");
      } else {
        textWrapper.css("animation-play-state", "running");
      }
    });
  });
};

export default initMarquee;
