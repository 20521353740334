// import { $ } from "vendors/index";

const adInit = function($) {
  var ad = $(".landing-advertise");

  if (!ad.length) return;
  $("body").css("overflow", "hidden");
  var timeoutId = undefined;
  var counter = ad.find("[data-counter]");
  var urlSource = ad.find("img").attr("src");
  var ctime = getParameterByName("ctime", urlSource);
  var redirectUrl = getParameterByName("url", urlSource);

  if (ctime) {
    counter.attr("data-counter", ctime);
  }

  if (redirectUrl) {
    var newUrl = "";
    if (!(redirectUrl.includes("http://") || redirectUrl.includes("https://"))) {
      newUrl += "http://" + redirectUrl;
    } else {
      newUrl = redirectUrl;
    }
    ad.find(".ad-image").append(
      $(`<a href="${newUrl}" target="_blank" style="position: absolute; top: 0; right: 0; bottom: 0; left: 0;"></a>`)
    );
  }

  function closeAd() {
    ad.addClass("ad-close");
    clearTimeout(timeoutId);
    $("body").removeAttr("style");
  }

  function countDown() {
    counter.attr("data-counter", counter.attr("data-counter") - 1);
    var count = counter.attr("data-counter");
    counter.text(count);
    if (count <= 0) {
      closeAd();
    } else {
      timeoutId = setTimeout(countDown, 1000);
    }
  }

  function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  var imageLoaded = false;

  ad.find(".ad-image img").on("load", function() {
    timeoutId = setTimeout(countDown, 1000);
    imageLoaded = true;
  });

  setTimeout(function() {
    if (!imageLoaded) {
      timeoutId = setTimeout(countDown, 1000);
    }
  }, 5000);

  ad.find(".btn-close").click(closeAd);
};

export default adInit;
