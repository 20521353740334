interface IBannerCarouselData {
  appDownloadUrl?: string;
  csUrl?: string;
}

export interface IBannerCarouselRedirectionParams {
  redirectUrl: string;
  redirectGroup: number;
  redirectPage: string;
  isLoggedIn: boolean;
}

export default function initBannerCarouselData({ appDownloadUrl, csUrl }: IBannerCarouselData) {
  return function initBannerCarouselRedirection({
    redirectUrl,
    redirectGroup,
    redirectPage,
    isLoggedIn,
  }: IBannerCarouselRedirectionParams) {
    if (redirectGroup === 0) {
      /**
       * Promo
       */
      const theme = window.themeName;

      if (theme && theme.includes("wdl")) {
        return;
      } else {
        if (redirectPage !== "default") {
          location.href = "promo.html#" + redirectPage;
        } else {
          location.href = "promo.html";
        }
      }
    } else if (redirectGroup === 1) {
      /**
       * Aff
       */
      location.href = "affindex.html";
    } else if (redirectGroup === 2) {
      if (redirectUrl.includes("/load")) {
        if (isLoggedIn) {
          const lottery = redirectUrl;
          const lotteryArr = lottery.substring(1).split("/");
          const lotteryHall = "/player/lottery";

          /**
           * If no game is selected, go to default lottery
           */
          if (lotteryArr.length === 1) {
            location.href = lotteryHall;
          } else {
            const game = lotteryArr[1];
            location.href = `${lotteryHall}/${game}`;
          }
        } else {
          location.href = "register.html";
        }
      }
    } else if (redirectGroup === 3) {
      /**
       * Product
       */
      if (isLoggedIn) {
        location.href = "/player/lottery?home=true&product=" + redirectPage;
      } else {
        location.href = "register.html";
      }
    } else if (redirectGroup === 4) {
      /**
       * Other
       */
      switch (redirectUrl) {
        case "/share": // APP download
          if (appDownloadUrl) {
            window.open(appDownloadUrl);
          }

          return;
        case "/faq": // FAQ
          location.href = "faqs.html";
          return;
        case "/customerservice": // 在线客服
          if (csUrl) {
            window.open(csUrl);
          }

          return;
        case "/wechatAdmin": // 微信飞单
          $("html").append(
            `<div 
                id="wechat-admin-modal"
                style="
                position: fixed;
                z-index: 999;
                top: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100vw;
                height: 100vh;
                background-color: rgba(0,0,0,0.4);"
                >
                <iframe width="640" height="498" src="https://v.qq.com/iframe/player.html?vid=p03972tll8k&tiny=0&auto=0" frameborder allowfullscreen/>
             </div>
            `
          );

          $("#wechat-admin-modal").on("click", function () {
            $(this).remove();
          });

          return;
      }

      if (!isLoggedIn) {
        location.href = "register.html";
        return;
      }

      switch (redirectUrl) {
        case "/chatroom":
          location.href = "/player/lottery?redirect=chatroom";
          break;
        case "/interestWallet":
          location.href = "/player/lottery?redirect=yuebao";
          break;
        case "/payment/deposit":
          window.open("/payment/lontong/recharge");
          break;
        case "/payment/withdrawl":
          window.open("/player/withdrawal");
          break;
        case "/prediction/list":
          location.href = "/player/lottery?predict=true&lottery=default";
          break;
        case "/vip":
          location.href = "/player/lottery?redirect=VIP";
          break;
        case "/signin":
          location.href = "/player/lottery?redirect=checkin";
          break;
        case "/referral":
          location.href = "/player/lottery?redirect=referral";
          break;
        case "/loyalty":
          location.href = "/player/lottery?redirect=loyalty";
          break;
        default:
          location.href = "/player/lottery";
      }
    } else if (redirectGroup === 5) {
      window.open(redirectUrl);
    }
  };
}
