// depends on jQuery, Pace
// section active detection
const initSectionActiveDetection = function($) {
  var sections = $("section");
  $(window).on("scroll", detectActive);

  function detectActive() {
    sections.each(function(i, elem) {
      const boundries = elem.getBoundingClientRect();
      if (boundries.top < window.innerHeight - 200 && boundries.bottom > 200) {
        $(elem).addClass("active");
      } else {
        $(elem).removeClass("active");
      }
    });
  }

  if (window.Pace) {
    window.Pace.on("done", detectActive);
  } else {
    $(window).load(detectActive);
  }
};

export default initSectionActiveDetection