import ApiConfig from "./categories/config";
import ApiAccount from "./categories/account";
import ApiNotice from "./categories/notice";
import ApiPromo from "./categories/promo";

class Api {
  ajax: any;
  config: ApiConfig;
  account: ApiAccount;
  notice: ApiNotice;
  promo: ApiPromo;
  constructor(axios) {
    this.ajax = axios.create({
      // Allow ip only address to call API also
      baseURL: /localhost:56\d{2}/.test(location.host) ? "/api" : "",
    });
    this.init();
  }
  init = () => {
    this.config = new ApiConfig(this.ajax);
    this.account = new ApiAccount(this.ajax);
    this.notice = new ApiNotice(this.ajax);
    this.promo = new ApiPromo(this.ajax);
  };
}

export default Api;

export interface IApi extends ApiConfig, ApiAccount, ApiNotice, ApiPromo {}
