import { translate } from "src/lib/translate";

export const getApUid = () => {
  const PREFIX = "sg282";
  const CHARS = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let output = "";
  for (let i = 0; i < 8; i++) {
    output += CHARS.charAt(Math.floor(Math.random() * CHARS.length));
  }
  return PREFIX + output;
};

class ApiConfig {
  ajax: any;
  constructor(ajax) {
    this.ajax = ajax;
  }
  getPublicConfigs = (callback: (response) => void) => {
    const { ajax } = this;
    ajax.get("/web/rest/publicConfigs", { headers: { apuid: getApUid() } }).then(function (response) {
      if (response) {
        if (callback) {
          callback(response);
        }
      }
    });
  };

  getMbConfigs = (callback: (response) => void): void => {
    const { ajax } = this;
    ajax
      .get("/web/rest/mbconfig", { headers: { apuid: getApUid() } })
      .then(function (response) {
        if (response) {
          if (callback) {
            callback(response);
          }
        }
      })
      .catch(function (error) {
        document.querySelector("body").classList.add("ready");
        if (window.alertBox) {
          window.alertBox({
            message: translate("暂时无法连接服务器，请稍后再试！"),
            type: "info",
            confirmFunc: function () {
              location.reload();
            },
          });
        }
        const errorDom = document.createElement("div");
        const errorDomStyle = {
          position: "fixed",
          bottom: "4px",
          right: "4px",
          color: "#fff",
        };
        errorDom.style.position = errorDomStyle.position;
        errorDom.style.bottom = errorDomStyle.bottom;
        errorDom.style.right = errorDomStyle.right;
        errorDom.style.color = errorDomStyle.color;
        errorDom.innerHTML = error.toString();

        document.querySelector("body").appendChild(errorDom);
      });
  };

  generateVCode = (callback) => {
    const { ajax } = this;
    ajax
      .request({
        url: "/web/rest/code",
        method: "get",
        responseType: "arraybuffer",
        headers: { apuid: getApUid() },
        params: { _: Date.now() },
      })
      .then(function (response) {
        if (response) {
          const imgString = "data:image/png;base64," + new Buffer(response.data, "binary").toString("base64");

          if (callback) {
            callback(imgString);
          } else {
            return imgString;
          }
        }
      });
  };
}

export default ApiConfig;
